<template>
  <transition
    name="slide-up"
    appear
    mode="out-in">
    <div>
      <!-- course-currently-being-fetched -->
      <div
        v-if="isFetchingCourse"
        class="my-12">
        <v-progress-linear
          indeterminate
          color="primaryColor"></v-progress-linear>
      </div>
      <!-- course-fetched-successfully -->
      <div
        v-else-if="!isFetchingCourse && courseSuccessfullyFetched"
        class="relative">
        <!-- blurred course cover -->
        <div
          :style="`background-image: url('${course.coverUrl}');`"
          style="background-size: cover; background-position: center; height: 240px">
          <div style="width: 100%; height: 100%; backdrop-filter: blur(40px)">
            <v-btn
              icon
              color="white"
              class="absolute back-button"
              @click="$router.back()">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- course details -->
        <course-details-component
          :course="course"
          @toggleFavouriteStatus="toggleFavouriteStatus"
          @completeCourse="completeCourse" />
      </div>
    </div>
  </transition>
</template>

<script>
  import CourseDetailsMixin from '@/views/courses/course-details/CourseDetails.mixin.vue';
  export default {
    mixins: [CourseDetailsMixin],
  };
</script>

<style lang="css" scoped>
  .course-details-section {
    padding: 32px 24px;
    background: #ffffff;
    border-radius: 40px;
  }
  .course-details-section-title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: var(--v-brandGrey2-base);
  }
  .course-about-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: var(--v-brandGrey1-base);
  }
  .course-cover {
    border-radius: 24px;
  }
  .tap-pic {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: var(--v-brandGrey2-base);
  }

  .back-button {
    top: 10px;
    left: 30px;
  }

  ::v-deep .mdi-arrow-left {
    background: transparent !important;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: white;
  }
</style>
