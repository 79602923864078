<script>
  import CourseDetailsComponent from '@/components/courses/course-details/course-details-component/CourseDetailsComponent.vue';
  import { mapActions, mapMutations } from 'vuex';
  import { logAnalyticsEvent } from '@/utils';

  export default {
    name: 'course-details',
    data() {
      return {
        isFetchingCourse: false,
        courseSuccessfullyFetched: false,
        course: null,
      };
    },
    components: {
      CourseDetailsComponent,
    },
    computed: {
      courseId() {
        return this.$route.params.courseId;
      },
    },
    async created() {
      await this.fetchCourse();
      logAnalyticsEvent('course_opened', {
        course_id: this.courseId,
      });
      logAnalyticsEvent('screen_view', {
        page_title: this.course.title,
        course_id: this.courseId,
      });
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage']),
      ...mapActions('CoursesModule', ['fetchOne']),
      async fetchCourse() {
        this.courseSuccessfullyFetched = false;
        this.isFetchingCourse = true;

        try {
          const { data } = await this.fetchOne(this.courseId);
          // Firebase doesn't return the course id for some reason!
          this.course = { ...data, id: this.courseId };
          this.courseSuccessfullyFetched = true;
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isFetchingCourse = false;
        }
      },
      toggleFavouriteStatus() {
        this.$set(this.course, 'isFavorite', !this.course.isFavorite);
      },
      completeCourse() {
        this.$set(this.course, 'progress', 100);
      },
    },
  };
</script>
